<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Rates per person') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mini-gap is-mobi">
          <div class="column is-3">
            <Filter
              class="per-person-filter"
              :steps="steps"
              @clear-filter="resetList"
              @filter="fetchData"
            />
          </div>
          <div class="column" style="overflow-x: hidden">
            <a-spin v-if="params != null" :spinning="isFetching">
              <div v-show="!isFetching" class="price-tables">
                <a-button
                  class="page-arrows"
                  type="link"
                  @click.prevent.stop="fetchPreviousDate()"
                >
                  <CaretUpOutlined />
                  <span class="page-arrows__label">
                    {{ $filters.date(previousDate, { format: 'MM/DD' }) }}
                  </span>
                </a-button>
                <div class="ant-table ant-table-default ant-table-layout-fixed">
                  <div class="ant-table-content">
                    <div class="ant-table-scroll">
                      <div class="ant-table-body" style="overflow-x: auto">
                        <table
                          v-for="date in Object.keys(dates).sort()"
                          :key="date"
                          class="price-table"
                          :style="{ width: tableWidth + 'px' }"
                        >
                          <thead>
                            <th
                              :style="{ width: baseWidth + 'px' }"
                              class="price-table__title sticky-left"
                            >
                              {{ $filters.date(date, { format: 'MM/DD' }) }}
                            </th>
                            <th
                              v-for="pax in Object.keys(paxes)"
                              :key="pax"
                              :style="{ width: baseWidth + 'px' }"
                            >
                              {{ $t(pax) }}
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="price-table__title sticky-left">
                                {{ vendor?.name }}
                              </td>
                              <td v-for="pax in Object.keys(paxes)" :key="pax">
                                <span v-if="rates.external[date]">
                                  {{ rates.external[date][pax].unit }}
                                  {{ $filters.number(rates.external[date][pax].price) }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="price-table__title sticky-left">
                                {{ $t('PIOP Price') }}
                              </td>
                              <td v-for="pax in Object.keys(paxes)" :key="pax">
                                <span v-if="rates.piop[date]">
                                  {{ rates.piop[date][pax].unit }}
                                  {{ $filters.number(rates.piop[date][pax].price) }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="price-table__title sticky-left">
                                {{ $t('Registering Price') }}
                              </td>
                              <td v-for="pax in Object.keys(paxes)" :key="pax">
                                <a-input-number
                                  v-if="form.rates[date] != null && form.rates[date][pax] != null"
                                  v-model:value="form.rates[date][pax].price"
                                  :formatter="v => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                                  :parser="v => v.replace(/(,*)/g, '')"
                                  class="price-input has-text-right"
                                  size="large"
                                  :min="0"
                                  :controls="false"
                                  :disabled="date < today"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <a-button
                  class="page-arrows"
                  type="link"
                  @click.prevent.stop="fetchNextDate()"
                >
                  <CaretDownOutlined />
                  <span class="page-arrows__label">
                    {{ $filters.date(nextDate, { format: 'MM/DD' }) }}
                  </span>
                </a-button>
              </div>
            </a-spin>
            <a-empty v-else>
              <template #description>
                <span>{{ $t('no data') }}</span>
              </template>
            </a-empty>
          </div>
        </div>
        <div class="columns is-mini-gap is-mobi is-multiline">
          <div class="column has-text-right is-12 m-t-2x">
            <a-button
              :disabled="!params || isFetching"
              :size="size"
              class="button-secondary-outline rate-save-btn"
              style="min-width: 115px"
              @click="handleExportCsv"
            >
              {{ $t('Export to CSV') }}
            </a-button>
            <Modal>
              <template #default="{ hide }">
                <SubmitExternalConfirm
                  :rates="generateData()"
                  :vendor="vendor"
                  :type="'PerPerson'"
                  @confirmed="() => {
                    handleConfirm();
                    hide();
                  }"
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <slot name="handler" :show="show">
                  <a-button
                    v-permission="['modify-rate']"
                    :disabled="!params"
                    :size="size"
                    class="rate-save-btn"
                    type="primary"
                    @click.prevent="show"
                  >
                    {{ $t('Submit to External') }}
                  </a-button>
                  <a-button
                    v-permission="['modify-rate']"
                    :disabled="!params"
                    :size="size"
                    class="rate-save-btn"
                    type="primary"
                    @click="handleSave"
                  >
                    {{ $t('Save') }}
                  </a-button>
                </slot>
              </template>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Rates per person": "Rates per person",
    "no data": "Please select property, room, and plan name",
    "savedData.success": "Save successful",
  },
  "ja": {
    "Rates per person": "１人あたりの料金",
    "no data": "物件、部屋、プラン名を選択してください",
    "savedData.success": "Save successful",
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons-vue';
import Filter from './components/Filter';
import Modal from '@/components/Modal';
import SubmitExternalConfirm from './components/SubmitExternalConfirm';

export default {
  name: 'RatesPerPerson',
  components: {
    CaretUpOutlined,
    CaretDownOutlined,
    Filter,
    Modal,
    SubmitExternalConfirm,
  },
  data() {
    return {
      size: 'large',
      isFetching: false,
      form: {
        rates: {},
      },
      rates: {
        external: {},
        piop: {},
      },
      data: {
        external: [],
        piop: [],
        registered: [],
      },
      today: dayjs(new Date()).format('YYYY-MM-DD'),
      params: undefined,
      dates: {},
      isRenderTable: true,
      steps: 1,
      paxes: {},
      vendor: null,
      baseWidth: 200,
    };
  },
  computed: {
    previousDate() {
      const date = Object.keys(this.dates).sort().shift() || null;

      return dayjs(date).subtract(1, 'day').format('YYYY-MM-DD');
    },
    nextDate() {
      const date = Object.keys(this.dates).sort().pop() || null;

      return dayjs(date).add(1, 'day').format('YYYY-MM-DD');
    },
    tableWidth() {
      return Object.keys(this.paxes).length * this.baseWidth + this.baseWidth;
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchNextDate() {
      if (!this.params) return;

      this.isFetching = true;
      const { availablePeriod } = this.params;
      const { toDate } = availablePeriod;
      const from = toDate ? dayjs(toDate).add(1, 'day') : dayjs();
      const to = dayjs(from).add(this.steps - 1, 'days');
      const dates = this.getDates(from, to);
      dates.forEach((date) => {
        this.dates[date] = {};
        Object.keys(this.paxes).forEach((pax) => {
          this.dates[date][pax] = { price: 0, unit: 'JPY' };
        });
      });
      try {
        await Promise.all([
          this.fetchExternalData(dates),
          this.fetchRegisteredRates(dates),
          this.fetchPiopRates(dates),
        ]);

        const { query } = this.$route;
        const lastDate = Object.keys(this.dates).sort().pop();
        await this.$router.push({
          query: {
            ...query,
            dateTo: lastDate,
          },
        });
        this.params.availablePeriod.toDate = lastDate;
      } finally {
        this.isFetching = false;
      }
    },
    async fetchPreviousDate() {
      if (!this.params) return;
      this.isFetching = true;
      const { availablePeriod } = this.params;
      const { fromDate } = availablePeriod;
      const to = fromDate ? dayjs(fromDate).subtract(1, 'day') : dayjs();
      const from = dayjs(to).subtract(this.steps - 1, 'days');
      const dates = this.getDates(from, to);
      dates.forEach((date) => {
        this.dates[date] = {};
        Object.keys(this.paxes).forEach((pax) => {
          this.dates[date][pax] = { price: 0, unit: 'JPY' };
        });
      });

      await this.fetchExternalData(dates);
      await this.fetchRegisteredRates(dates);
      await this.fetchPiopRates(dates);

      const { query } = this.$route;
      const firstDate = Object.keys(this.dates).sort().shift();
      await this.$router.push({
        query: {
          ...query,
          dateFrom: firstDate,
        },
      });
      this.params.availablePeriod.fromDate = firstDate;

      this.isFetching = false;
    },
    parsePrice(rates) {
      if (rates == null || !rates.length) return {};
      const parsedData = {};
      rates.forEach(({ availableDate, ratePerPerson }) => {
        if (!ratePerPerson.length) return;
        const rate = ratePerPerson.find(({ rateKind }) => rateKind.toString() === '2') || {};
        const { currencyCode: unit } = rate;
        parsedData[availableDate] = {};
        Object.entries(this.paxes).forEach(([key, value]) => {
          parsedData[availableDate][key] = { price: Number(rate[value]) || 0, unit };
        });
      });

      return parsedData;
    },
    buildParamsFromQueries() {
      const {
        vendor,
        propertyId: hotelNo,
        roomCode,
        plan: planId,
        dateFrom: fromDate,
        dateTo: toDate,
      } = this.$route.query;

      if (vendor && hotelNo && roomCode && planId && fromDate && toDate) {
        return {
          vendor, hotelNo, roomCode, planId, availablePeriod: { fromDate, toDate },
        };
      }

      return null;
    },
    generatePaxes({ maximumCapacity, minimumCapacity }) {
      if (!this.paxes.length) {
        new Array(maximumCapacity - minimumCapacity + 1)
          .fill(0)
          .forEach((_, i) => {
            const key = `Pax ${i + minimumCapacity}`;
            this.paxes[key] = `unitRateValueWith${i + minimumCapacity}Person`;
          });
      }
    },
    async fetchData(dates = []) {
      const params = this.buildParamsFromQueries();
      if (!params) return;

      this.dates = {};
      this.form.rates = {};

      this.isFetching = true;
      if (!this.vendors?.length) {
        this.vendors = await this.$store.dispatch('iscm-price-auto-reflection/listExternalVendors');
      }

      this.vendor = this.vendors?.find((v) => v.code === params.vendor);
      try {
        const { rooms } = await this.$store.dispatch(
          'iscm-properties/listRoom',
          {
            ...params,
            type: 'PlanRateConditionPerPerson',
          },
        );
        const room = rooms?.find((room) => room.roomCode === params.roomCode);
        if (room !== null) this.generatePaxes(room);
      } catch (error) {
        this.$message.error(error);
        this.$router.replace({ query: null });
        this.resetList();
      }

      const { fromDate, toDate } = params.availablePeriod;
      this.getDates(dayjs(fromDate), dayjs(toDate))
        .forEach((date) => {
          this.dates[date] = {};
          Object.keys(this.paxes).forEach((pax) => {
            this.dates[date][pax] = { price: 0, unit: 'JPY' };
          });
        });
      this.params = params;

      try {
        await this.fetchExternalData(dates);
        await this.fetchRegisteredRates(dates);
        await this.fetchPiopRates(dates);
      } finally {
        this.isFetching = false;
      }
    },
    async fetchExternalData(dates = null) {
      try {
        const { rateConditionPerPerson } = await this.$store.dispatch(
          'iscm-external/ratePerPerson', { ...this.params, dates },
        );
        this.data.external = this.data.external.concat(rateConditionPerPerson);
        this.rates.external = {
          ...JSON.parse(JSON.stringify(this.dates)),
          ...this.rates.external,
          ...this.parsePrice(rateConditionPerPerson),
        };
      } catch (error) {
        this.rates.external = { ...this.dates };
      }
    },
    async fetchPiopRates(dates = []) {
      try {
        const { rateConditionPerPerson } = await this.$store.dispatch(
          'iscm-piop/ratePerPerson', { ...this.params, dates },
        );
        this.data.piop = this.data.piop.concat(rateConditionPerPerson);
        this.rates.piop = {
          ...JSON.parse(JSON.stringify(this.dates)),
          ...this.rates.piop,
          ...this.parsePrice(rateConditionPerPerson),
        };
        this.applyPiopRateToRegistered();
      } catch (error) {
        this.$message.error('Error Fetch data');
      }
    },
    async fetchRegisteredRates(dates = []) {
      let data = {};
      try {
        data = await this.$store.dispatch(
          'iscm-savedData/ratePerPerson', { ...this.params, dates },
        );
      } finally {
        const { rateConditionPerPerson } = data;
        this.data.registered = this.data.registered.concat(rateConditionPerPerson || []);
        this.form.rates = {
          ...JSON.parse(JSON.stringify(this.dates)),
          ...this.form.rates,
          ...this.parsePrice(rateConditionPerPerson || []),
        };
      }
    },
    applyPiopRateToRegistered() {
      Object.entries(this.rates.piop).forEach(([date, rates]) => {
        Object.entries(rates).forEach(([pax, rate]) => {
          if (this.form.rates[date][pax].price <= 0) {
            this.form.rates[date][pax] = { ...rate };
          }
        });
      });
    },
    calculateRegisteringPrice() {
      // calculate the price "PIOP price" * "Rate Calculation Base Pax"
      return 0;
    },
    getDates(from, to) {
      const dates = [];
      let currentDate = from;

      while (currentDate <= to) {
        dates.push(dayjs(currentDate).format('YYYY-MM-DD'));
        currentDate = dayjs(currentDate).add(1, 'day');
      }

      return dates;
    },
    generateData() {
      const rateConditionPerPerson = Object.entries(this.form.rates)
        .filter(([date, rates]) => {
          const res = date >= this.today && Object.values(rates).some((rate) => rate.price > 0);
          return res;
        })
        .map(([date, rate]) => {
          const paxes = {};
          Object.entries(this.paxes).forEach(([key, paxKey]) => {
            paxes[paxKey] = Number(rate[key].price) || 0;
            paxes.currencyCode = rate[key].unit;
          });

          return {
            availableDate: date,
            ratePerPerson: [
              {
                rateKind: 2,
                ...paxes,
              },
            ],
          };
        });

      return { ...this.params, rateConditionPerPerson };
    },
    async handleSave() {
      try {
        const payload = this.generateData();
        const { success } = await this.$store.dispatch('iscm-savedData/saveRatePerPerson', payload);
        if (success) this.$message.success(this.$t('savedData.success'));
      } catch (error) {
        this.$message.error('Error save data');
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    async handleConfirm() {
      this.form.rates = {
        ...JSON.parse(JSON.stringify(this.dates)),
        ...JSON.parse(JSON.stringify(this.rates.piop)),
      };
      const dates = Object.keys(this.dates);
      this.isFetching = true;
      try {
        await this.fetchExternalData(dates);
      } finally {
        this.isFetching = false;
      }
    },
    handleExportCsv() {
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
      try {
        const dates = Object.keys(this.dates).sort();
        const paxes = Object.keys(this.paxes);
        const rateNames = [
          `${this.vendor?.name.replace(/ /m, '')}Price`,
          'PiopPrice',
          'RegisteringPrice',
        ];
        const headRows = ['date'].concat(rateNames.map((rate) => {
          const cols = paxes.map((pax) => rate + pax.replace(/ /m, ''));
          return cols;
        })).flat();

        const rows = [headRows].concat(dates.map((date) => {
          const row = [dayjs(date).format('MM/DD/YYYY')];
          [this.rates.external[date], this.rates.piop[date], this.form.rates[date]].forEach((r) => {
            paxes.forEach((pax) => row.push(r[pax]?.price));
          });
          return row;
        }));
        const csvContent = rows.map((row) => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        const fileName = [
          'rates_per_person',
          this.vendor?.name.replace(/ /m, ''),
          `h${this.params.hotelNo}`,
          `r${this.params.roomCode}`,
          `p${this.params.planId}`,
          dayjs(dates[0]).format('YYYYMMDD'),
          dayjs(dates.slice(-1)).format('YYYYMMDD'),
        ].join('_');

        saveAs(blob, `${fileName}.csv`);
      } catch (error) {
        this.$message.error(error || 'Could not able to export csv');
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    fetch(obj, ...keys) {
      let value = obj;
      for (const key of keys) {
        if (key != null) {
          if (typeof key === 'object' && key instanceof Array) {
            let item = [key].flat();
            const result = value;
            item = item.map((key) => [key, result && result[key]]);
            if (item.length <= 1) [[, value]] = item;
            else value = Object.fromEntries(item);
          } else {
            const childKeys = key.split('.');
            if (childKeys.length > 1) {
              value = this.fetch(value, ...childKeys);
            }
          }
        }
      }
      return value;
    },
    resetList() {
      this.rates = {
        external: {},
        piop: {},
      };
      this.dates = {};
      this.params = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rate-save-btn {
      margin-left: 0.8rem;
      margin-bottom: 0.8rem;
      min-width: 115px;
    }

.price-input::v-deep {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

.price-tables::v-deep {
  margin-left: 0.5rem;

  .page-arrows {
    padding: 10px 0;
    margin-bottom: 10px;

    .page-arrows__label {
      color: $black;
      font-weight: normal;
    }
  }
}

.price-table::v-deep {
  th {
    background-color: $tab-head-bg;
    color: $white;
    padding: 5px 16px;
  }

  td {
    padding: 16px;
  }

  th,
  td {
    text-align: right;
    padding-right: 24px;
  }

  th.price-table__title,
  td.price-table__title {
    padding-left: 16px;
    padding-right: 16px;
    text-align: left;
    border-right: 1px solid #DCDCDC;
    white-space: nowrap;
  }

  tr td {
    background: #fff;
  }

  tr:nth-child(even) td {
    background-color: #F5F5F5;
  }
}
.ant-spin-nested-loading {
  min-height: 100%;
}

.sticky-left {
  left: 0;
  position: sticky;
  z-index: 1;
}

.price-input::v-deep {
  width: 100%;

  input {
    text-align: right;
  }

  .ant-input-number-handler-wrap {
    display: none!important;
  }
}
</style>
